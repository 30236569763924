import axios from "axios";
import Moment from "moment";
import React, {useState,useEffect} from "react"
import {Link} from "react-router-dom";



const ModalModifProjet = ({showModal,setShowModal,projet}) =>{

    const showHideClasseName = showModal ? "modal params open" : "modal params close";


    const [nomProjet,setNomProjet] = useState(projet.NameProjet)
    const [coutTicket,setcoutTicket] = useState(projet.Cost)
    const [enveloppe,setenveloppe] = useState(projet.Enveloppe)

    const [creditEnveloppe, setcreditEnveloppe] = useState()
    const [descriptionEnveloppe, setdescriptionEnveloppe] = useState()
    const [dateEnveloppe, setdateEnveloppe] = useState(Date.now())


    const [coutPeriode, setcoutPeriode] = useState()
    const [debutPeriode, setdebutPeriode] = useState()
    const [finPeriode, setfinPeriode] = useState()

    const [noExpiration,setNoExpiration] = useState(false);
    const [nombreEnveloppeToGenerate,setNombreEnveloppeToGenerate] = useState(projet.enveloppeToGenerate)
    const [modeProjet,setModeProjet] = useState(projet.modeProjet)
    const [statutProjet,setStatutProjet] = useState(projet.statut)

    const [logo,setLogo] = useState(projet.UrlLogo)

    useEffect(()=>{
        setLogo(projet.UrlLogo)
    },[projet])



    const addCredit = (e) => {
        e.preventDefault()

        console.log('Add Credit')

        let formData = new FormData()
        formData.append('credit',creditEnveloppe)
        formData.append('fk_idProjet',projet.idProjet)
        formData.append('date',Moment().format('YYYY-MM-DD'))
        formData.append('description',descriptionEnveloppe)

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL+"enveloppe",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })

    }

    const deleteCredit = (id) =>{

        let qs = 'idEnveloppe='+id

        axios({
            method: "delete",
            url: process.env.REACT_APP_API_URL+"enveloppe",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs,
          })
        
    }


  
    const handleSumbit = (e) => {
        e.preventDefault()

     
        let qs = 'idProjet='+projet.idProjet+'&NameProjet='+nomProjet+'&Cost='+coutTicket+'&Enveloppe='+enveloppe
 
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"projets",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        }).then(()=>{
            setShowModal(false)    
        })
  
    }

    const addCoutPeriode = (e) => {

        e.preventDefault()

        console.log('Add Credit')

        let date_finperiode = noExpiration ? '2099-12-31' : finPeriode

        let formData = new FormData()
        formData.append('cout',coutPeriode)
        formData.append('fk_idProjet',projet.idProjet)
        formData.append('date_debut',debutPeriode)
        formData.append('date_fin',date_finperiode)

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL+"cout",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })



    }
        

    const deleteCout = (id) =>{

        let qs = 'idCout='+id

        axios({
            method: "delete",
            url: process.env.REACT_APP_API_URL+"cout",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs,
          })
        
    }

    const handleNoExpiration = () =>{
        console.log(noExpiration)
        setNoExpiration(!noExpiration)
    }

    const changeEnveloppeToGenerate = (e) => {
        e.preventDefault();

        let qs = 'idProjet='+projet.idProjet+'&enveloppeToGenerate='+nombreEnveloppeToGenerate
 
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"projets",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        })

    } 

    const modifModeProjet = (modeProjet) => {
        setModeProjet(modeProjet)
        let qs = 'idProjet='+projet.idProjet+'&modeProjet='+modeProjet
 
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"projets",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        })

    }

    const modifStatutProjet = (statut) => {
        console.log('modif-statut-projet')

        setModeProjet(modeProjet)
        let qs = 'idProjet='+projet.idProjet+'&status='+statut
 
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"projets",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        })
    }


    const copyToClipboard = async (text) =>{
        await navigator.clipboard.writeText(text);
    }

    const validateFormulaire = (e) =>{
        if(coutPeriode && debutPeriode && finPeriode){
            addCoutPeriode(e)
        }
        if(creditEnveloppe && descriptionEnveloppe && dateEnveloppe){
            addCredit(e)
        }
        if(projet.modeProjet === 'budget-plancher' && nombreEnveloppeToGenerate){
            changeEnveloppeToGenerate(e)
        }
    }

    const deleteLogo = () => {
        console.log('Delete logo')
        setLogo('')
    }
    const updateLogo = (file) => {

        let formData = new FormData()
        formData.append('idProjet',projet.idProjet)
        formData.append('logo',file,file.name)
        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL+"projets/logo",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
    }
        return(
            
            <div className={showHideClasseName}>
                <div className="modal-container">
              
                <div className="modal-header">
                    <h1>Paramètres du projet</h1>
                    <button type="button" className="close" onClick={()=>{setShowModal(false)}}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z" fill="black"/>
                        </svg>
                    </button>
                </div>
                <div className="modal-content">
            
                    <div class="modules">

                        <div className="informations">
                            <h3>Mode du projet</h3>
                            <form>
                                <select value={modeProjet} onChange={(e)=>{modifModeProjet(e.target.value)}}>
                                        <option value="classique">Suivi au réel</option>
                                        <option value="enveloppe">Enveloppe</option>
                                        <option value="budget-plancher">Budget plancher</option>
                                </select>
                            </form>

                            {projet.modeProjet === 'budget-plancher' &&
                            <div className="modif-mode">
                                <p>Enveloppe en euro à générer par mois</p>
                                <form onSubmit={changeEnveloppeToGenerate}>
                                    <input type="text" placeholder="Enveloppe non renseignée" value={nombreEnveloppeToGenerate} onChange={(e)=>{setNombreEnveloppeToGenerate(e.target.value)}}/>
                                </form>
                            </div>
                            }
                        </div>

                        <div class="statut">
                            <h3>Statut du projet</h3>

                            <form>
                                <select value={statutProjet} onChange={(e)=>{modifStatutProjet(e.target.value)}}>
                                        <option value="current">En cours</option>
                                        <option value="archived">Archivé</option>
                                </select>
                            </form>

                        </div>

                        <div className="modif-logo">
                            <h3>Modification du logo</h3>
                            {logo && <>
                            <img src={process.env.REACT_APP_API_URL+'uploads/'+projet.UrlLogo} />
                            </>}
                            {!logo && <>
                                <input className="choix-img" onChange={(e) => updateLogo(e.target.files[0])} accept="image/png, image/jpeg, .svg" type="file" name="logo"/>
                            </>}
                            <span onClick={(e) => {deleteLogo()}}>Modifier</span>

                        </div>

                        <div className="partage-client">
                            <h3>Partage client</h3>
                            <div className="info">
                                <Link to={`/client/${projet.uuid}`}>https://ticket.r2.fr/client/{projet.uuid}</Link>
                                <svg onClick={(e)=>{copyToClipboard('https://ticket.r2.fr/client/'+projet.uuid)}}  aria-hidden="true" focusable="false" data-prefix="far" data-icon="copy" class="svg-inline--fa fa-copy fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path></svg>
                            </div>
                            <div className="info">
                                <span>{projet.passwordClient}</span>
                                <svg onClick={(e)=>{copyToClipboard(projet.passwordClient)}} aria-hidden="true" focusable="false" data-prefix="far" data-icon="copy" class="svg-inline--fa fa-copy fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path></svg>
                            </div>
                        </div>

                        {projet.modeProjet === 'budget-plancher' &&
                                <div className="enveloppes budget-plancher"> 
                                    <h2>Historique des crédits générés</h2>
                                    
                                    <div class="enveloppe head">
                                        <div class="date">
                                            Date
                                        </div>
                                        <div class="description">
                                            Description
                                        </div>
                                        <div class="credit">
                                            Crédit
                                        </div>
                                        <div class="delete">
                                        </div>
                                    </div> 
                                    {projet.Enveloppe &&  projet.Enveloppe.map(enveloppe => (
                                    <>
                                    <div class="enveloppe">
                                        <div class="date">
                                            {Moment(enveloppe.CreatedAt).format('L')}
                                        </div>

                                        <div class="description">
                                            {enveloppe.Description}
                                        </div>

                                        <div class="credit">
                                            {enveloppe.Credit}€
                                        </div>

                                    
                                        <div class="delete" onClick={(e)=>{deleteCredit(enveloppe.idEnveloppe)}}>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" class="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
                                        </div>
                                    </div> 
                                    </>))}
                                </div>
                            }

                        {projet.modeProjet === 'enveloppe' &&
                        <div className="gestion-enveloppe">
                        <h2>Gestion des enveloppes</h2>

                        <div class="enveloppes">

                           <div class="enveloppe head">
                                <div class="date">
                                    Date
                                </div>

                                <div class="description">
                                    Description
                                </div>


                                <div class="credit">
                                    Crédit
                                </div>

                               
                                <div class="delete">

                                </div>
                            </div> 

                            {projet.Enveloppe &&  projet.Enveloppe.map(enveloppe => (
                            <>
                            <div class="enveloppe">
                                <div class="date">
                                    {Moment(enveloppe.CreatedAt).format('L')}
                                </div>

                                <div class="description">
                                    {enveloppe.Description}
                                </div>

                                <div class="credit">
                                    {enveloppe.Credit}€
                                </div>

                             

                                <div class="delete" onClick={(e)=>{deleteCredit(enveloppe.idEnveloppe)}}>
                                     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" class="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
                                </div>
                            </div> 
                            </>))}
                        </div>

                        <form className="addCredit" onSubmit={(e) =>{ addCredit(e)}}>
                            <div>
                                <input type="number" value={creditEnveloppe} onChange={(e)=>{setcreditEnveloppe(e.target.value)}} placeholder="Enveloppe en €" />
                            </div>
                            <div>
                                <input type="text"  value={descriptionEnveloppe} onChange={(e)=>{setdescriptionEnveloppe(e.target.value)}} placeholder="Description" />
                            </div>
                            <div>
                                <input type="date" value={dateEnveloppe} onChange={(e)=>{setdateEnveloppe(e.target.value)}} placeholder="Date" />
                            </div>
                        </form>
                        </div>
                        }

                        <div className={projet.modeProjet!='enveloppe' ? 'gestion-cout-66' : 'gestion-cout'}>

                        <h2>Gestion des coût</h2>
                            <div class="enveloppes">
                                <div class="enveloppe head">
                                        <div class="description">
                                            Début
                                        </div>

                                        <div class="description">
                                            Fin
                                        </div>


                                        <div class="credit">
                                            Coût
                                        </div>

                                    
                                        <div class="delete">

                                        </div>
                                    </div> 

                                    {projet.Costs &&  projet.Costs.map(cout=> (
                                    <>
                                    <div class="enveloppe">
                                        <div class="description">
                                            {Moment(cout.dateDebut).format('L')}
                                        </div>

                                        <div class="description">
                                            {Moment(cout.dateFin).format('L') !== '31/12/2099' ? Moment(cout.dateFin).format('L') : 'Pas de date de fin'}
                                        </div>
                                    

                                        <div class="credit">
                                            {cout.cout}€
                                        </div>

                                    

                                        <div class="delete" onClick={(e)=>{deleteCout(cout.idCout)}}>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" class="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
                                        </div>
                                    </div> 
                                    </>
                                    ))}
                                </div>

                                <form className="addCredit" onSubmit={(e) =>{ addCoutPeriode(e)}}>
                                
                                
                                <div>
                                    <input type="date" value={debutPeriode} onChange={(e)=>{setdebutPeriode(e.target.value)}} placeholder="Date de debut" />
                                </div>
                                <div>
                                
                                    {!noExpiration &&
                                    <input type="date" value={finPeriode} onChange={(e)=>{setfinPeriode(e.target.value)}} placeholder="Date de fin" /> } 

                                    <div className={noExpiration ? 'expiration full-height' : 'expiration'}>
                                        <input type="checkbox" name="no-expiration" checked={noExpiration} onChange={handleNoExpiration}/>Pas de date de fin
                                    </div>
                                </div>

                                <div>
                                    <input type="number" value={coutPeriode} onChange={(e)=>{setcoutPeriode(e.target.value)}} placeholder="Coût en €" />
                                </div>
                            </form>


                            </div>

                           

                    </div>
                    <div class="validation">
                        <button onClick={validateFormulaire}>Valider</button>
                    </div>

                </div>
                </div>
            </div>
        )


}
export default ModalModifProjet