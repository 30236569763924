import React, {useState} from "react"
import axios from "axios"


const Task = (props) => {

    const { task } = props
    const [cost,setCost] = useState(task.costTask)

    const deleteTask = () => {
        console.log(task.idTask)
        let qs = 'idTask='+task.idTask
        axios({
            method: "delete",
            url: process.env.REACT_APP_API_URL+"task",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs,
          })
    }


    const increaseCost = () => {
        let newCost = parseFloat(cost) + 1
        setCost(newCost)
        let qs = 'idTask='+task.idTask+'&costTask='+newCost
        axios({
            method: "put",
            url: process.env.REACT_APP_API_URL+"task",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs,
          })
    }

    const reduceCost = () => {
        let newCost = parseFloat(cost) - 1
        setCost(newCost)
        let qs = 'idTask='+task.idTask+'&costTask='+newCost
        axios({
            method: "put",
            url: process.env.REACT_APP_API_URL+"task",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs,
          })
    }

    
    return (
            <div className="task" key={task.idTask}>
                
                    <div className="bloc-task">
                        {task.Category}
                    </div>
                    <div className="bloc-task">
                        {task.madeByTask}
                    </div>
                    <div className="bloc-task bloc-task-increments">
                        {!props.modeCout && <>

                            <div className="increments" onClick={reduceCost}>
                                <span>-</span> 
                            </div>

                            {task.costTask/4} heures 

                            <div className="increments" onClick={increaseCost}>
                                <span>+</span> 
                            </div>
                        </> }

                        {props.modeCout && <>
                            
                            <div className="increments">
                                <span onClick={reduceCost}>-</span>
                            </div>

                            {task.costTask} tickets 
                            <div className="increments">
                                <span onClick={increaseCost}>+</span>
                            </div>
                        </> }
                    </div>
                    <div className=" bloc-task control" onClick={deleteTask}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" className="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
                    </div>
            </div>
    )


}
export default Task;
