import React, {useState, useEffect} from "react"
import axios from "axios"
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { ReactSortable } from "react-sortablejs";
import ModalAddProjet from "./modals/ModalAddProjet";


const AllProjets = () => {
    const [projets,setProjets] = useState([])
    const [projetsFavoris,setProjetsFavoris] = useState([])
    const [projetsFavorisStart,setProjetsFavorisStart] = useState([])
    const [needReload, setneedReload] = useState(false)

    const [modalAddProjet, setModalAddProjet] = useState(false)

    const [displayProjetsArchived,setDisplayProjetsArchived] = useState(false)


    axios.defaults.headers.common['Authorization']  = 'Bearer '+localStorage.getItem('token')

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'projets').then((res) => {
            if(res.data.length>0){
                setProjets(res.data.filter(item => item.isFav === false))

                //Sort ProjetFavoris par position
                let projFav = res.data.filter(item => item.isFav === true)
                projFav.sort(function(a,b){return a.favPosition - b.favPosition})

                setProjetsFavoris(projFav)
                setProjetsFavorisStart(projFav)
                setneedReload(false)
            }
        })
       
    },[needReload])

    
    axios.interceptors.response.use(function (response) {
        if(response.config.url !== process.env.REACT_APP_API_URL+'projets' &&  response.config.url !== process.env.REACT_APP_API_URL+'search' &&!(response.config.url === process.env.REACT_APP_API_URL+"projets/favoris" && response.config.method === 'post')) {
            setneedReload(true)
        }
        return response
    })



    const moveFavoris = () => {

        let new_projetFav = []
        projetsFavoris.forEach((projet,key)=>{
                new_projetFav.push({idProjet:projet.idProjet,position:key+1})
        })
        console.log('New projetFav',new_projetFav)
        let formData = new FormData()
        formData.append('new_projetFav',JSON.stringify(new_projetFav))
        
        if(new_projetFav.length>0){
            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL+"projets/favoris",
                headers: { "Content-Type": "multipart/form-data" },
                data: formData
            })
        }

      

    }

    
    useEffect(()=>{
        console.log('isEqualProjetStart',projetsFavoris !== projetsFavorisStart)
        if(projetsFavoris !== projetsFavorisStart && projetsFavoris.length>0 &&  projetsFavorisStart.length>0){
            moveFavoris()
        }
    },[projetsFavoris])



    // Trie des projets par ordre alphabétique dans le deuxième <ReactSortable>
    const orderProjets = [
        {value: 'az', label: 'A à Z'},
        {value: 'za', label: 'Z à A'},
    ]

    const handleOrderChange = (event) => {
        let sectionAllProjets = document.querySelector('#section-all-projets');
        if (event.value === 'az') {
            sectionAllProjets.classList.remove('reverse-order-projets');
        } else if (event.value === "za") {
            sectionAllProjets.classList.add('reverse-order-projets');
        }
    }
       
    const handleChangeSelector = (selector, event) => {
        if (selector === "order") {
            handleOrderChange(event);
        } 
    }
 
   

    return (
        <div className="main container-projets">
       
        <div className="projets">
        
            <h1>Mes projets favoris</h1>
            <ReactSortable tag="section" group="projets-shared" list={projetsFavoris} animation={200} setList={setProjetsFavoris}>
           
            {projetsFavoris.map((projet)=>(
                    
                    <div className="projet" id={`projet-${projet.idProjet}`} key={projet.idProjet}>
                        <Link to={`/projets/${projet.idProjet}`}>
                        <div className="content">
                            <img src={process.env.REACT_APP_API_URL+'uploads/'+projet.UrlLogo} alt="logo" />
                            <h2>{projet.NameProjet}</h2>
                        </div>
                        </Link>
    
                    </div>
                
            ))}
            </ReactSortable>

            <div className="titre-section">
                <h1>Tous les projets</h1>
                <div class="button add" onClick={()=>{setModalAddProjet(true)}}>
                            +
                </div> 
                <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
                    <div>
                        <Link to={`/suivi-budgets`} className="button suivi-budgets">
                            Suivi des budgets
                        </Link>
                    </div>
                    <div>
                        <input type="checkbox" name="displayProjetsArchived" checked={displayProjetsArchived ? true : false} onChange={(e)=>{setDisplayProjetsArchived(e.target.checked)}}/>
                        Afficher les projets archivés
                    </div>
                </div>
                <div class="select-order">
                    <p>Trier de :</p>
                    <Select options={orderProjets} defaultValue={{label: "A à Z", value: "az"}} onChange={event => handleChangeSelector("order", event)} />
                </div>

            
            </div>
            

            <ReactSortable tag="section" id="section-all-projets" group="projets-shared"  list={projets} animation={200} setList={setProjets} >
           
           {projets.filter((projet) => (displayProjetsArchived) || (projet.status !== 'archived' && !displayProjetsArchived)).map((projet)=>(
                   
                   <div className={"projet "+projet.status} id={`projet-${projet.idProjet}`} key={projet.idProjet} >
                       <Link to={`/projets/${projet.idProjet}`}>
                       <div className="content">
                           <img src={process.env.REACT_APP_API_URL+'uploads/'+projet.UrlLogo} alt="logo" />
                           <h2>{projet.NameProjet}</h2>
                       </div>
                       </Link>
                       
                   </div>
               
           ))}
           </ReactSortable>
        </div>
        <ModalAddProjet showModal={modalAddProjet} setShowModal={setModalAddProjet}/>
        </div>
        

    )


}
export default AllProjets;