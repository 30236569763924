import axios from "axios"
import {Link, useHistory} from 'react-router-dom';
import ModalExportPDF from "./modals/ModalExportPDF";
import React, {useState, useEffect, useCallback} from "react"
import ModalImportTicket from "./modals/ModalImportTicket";
import Moment from 'moment';
import ModalModifProjet from "./modals/ModalModifProjet";
import Chart from "./Chart";
import ModalInfosClient from "./modals/ModalInfosClient";
import ModalTempsRessources from "./modals/ModalTempsRessources";
import ModalAddTicket from "./modals/ModalAddTicket";
import NumberFormat from 'react-number-format';
import 'moment/locale/fr';
import Select from "react-select";

const InfoProjet = ({modeAffichage,setModeAffichage,periode,setPeriode,projet,VueClient,ticketsSearched}) => {

    const [modalExportPDF, setmodalExportPDF] = useState(false)
    const [modalImportTicket, setmodalImportTicket] = useState(false)
    const [modalModifProjet,setmodalModifProjet] = useState(false)
    const [modalInfosClient,setmodalInfosClient] = useState(false)
    const [modalTempsRessources,setmodalTempsRessources] = useState(false)
    const [modeAffichageMore,setmodeAffichageMore] =  useState(false)
    const [consommationperiode, setconsommationperiode] = useState()
    const [consommationeuroperiode, setconsommationeuroperiode] = useState()
    const [periodeString, setperiodeString] = useState()
    const [modalAddTicket,setModalAddTicket] = useState(false)
    const [modificationTitre, setmodificationTitre] = useState(false)
    const [titreProjet, setTitreProjet] = useState(projet.NameProjet)
    const [ticketsFind, setTicketsFind] = useState(ticketsSearched ? ticketsSearched : projet.tickets )
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [projetIsFav, setProjetIsFav] = useState(projet.isFav)

    const [consommationSinceStart,setConsommationSinceStart] = useState(0)


    useEffect(() => {
        setTicketsFind(ticketsSearched)
    }, [ticketsSearched])
    
    const history = useHistory();
    const deleteProjet = () =>{
        let qs = 'idProjet='+projet.idProjet

        axios({
            method: "delete",
            url: process.env.REACT_APP_API_URL+"projets",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs,
          }).then((res)=>{
            history.push("/projets")
          })        
    }

    const addFav = (idProjet) =>{
        console.log(idProjet)
        let qs = 'idProjet='+idProjet
            axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"projets/favoris",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
            })
            setProjetIsFav(true);
    }

    const deleteFav = (idProjet) =>{
        console.log(idProjet)
            let qs = 'idProjet='+idProjet
            axios({
                method: "delete",
                url: process.env.REACT_APP_API_URL+"projets/favoris",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs,
              })

            setProjetIsFav(false);
    }


    useEffect(() => {

        let tickets_find;
        let periodeToSelect = periode;
        let lastDayOfPeriod

        if(modeAffichage === 'annee'){
            tickets_find = projet.tickets.filter(ticket => Moment(ticket.CreatedAt).format('YY') === Moment(periodeToSelect).format('YY'))
            lastDayOfPeriod = Moment(periodeToSelect).endOf('year').format('X')
         }

         if(modeAffichage === 'trimestre'){
            tickets_find = projet.tickets.filter(ticket => Moment(ticket.CreatedAt).format('Q/YY') === Moment(periodeToSelect).format('Q/YY'))
            lastDayOfPeriod = Moment(periodeToSelect).endOf('quarter').format('X')
        }

         if(modeAffichage === 'mois'){
            tickets_find = projet.tickets.filter(ticket => Moment(ticket.CreatedAt).format('MM/YY') === Moment(periodeToSelect).format('MM/YY'))
            lastDayOfPeriod = Moment(periodeToSelect).endOf('month').format('X')
         }
 
         if(modeAffichage === 'tout'){
           tickets_find = projet.tickets
           lastDayOfPeriod = Moment().format('X')
         }


         const ticket_since_start = projet.tickets.filter(ticket => {
            return Moment(ticket.CreatedAt).unix() <= lastDayOfPeriod
        })


         let consommation_since_start_to_periode = 0 
         ticket_since_start.forEach((ticket)=> {
            consommation_since_start_to_periode += ticket.coutTicket * ticket.PriceTicket
         })

         setConsommationSinceStart(consommation_since_start_to_periode)

         let consommation = 0
         let consommation_euro = 0
         
         tickets_find.forEach(ticket =>  {
             consommation = consommation + ticket.coutTicket;
             consommation_euro = consommation_euro + (ticket.coutTicket*ticket.PriceTicket);
         })


         setconsommationperiode(consommation)
         setconsommationeuroperiode(consommation_euro)


         setTitreProjet(projet.NameProjet)
         setTicketsFind(tickets_find)

         if(modeAffichage === 'tout' && projet.tickets && projet.tickets.length>0 ){
            setperiodeString("Du " + Moment(projet.tickets[projet.tickets.length - 1].CreatedAt).format('L') + " au " + Moment().locale('fr').format('L'))
        }

         if(modeAffichage === 'annee'){
             setperiodeString(Moment(periode).format('Y'))
         }
        
         if(modeAffichage === 'mois'){
            setperiodeString(Moment(periode).locale('fr').format('MMMM Y'))
        }

        if(modeAffichage === 'trimestre'){
            setperiodeString(`${Moment(periode).format('Q')}${Moment(periode).format('Q') === '1' ? 'er': 'eme'} trimestre ${Moment(periode).format('Y')}`)
        }

    }, [modeAffichage,projet,periode])


    const optionsAffichage = [
        {value:'tout',label:'Tout'},
        {value:'annee',label:'Année'},
        {value:'trimestre',label:'Trimestre'},
        {value:'mois',label:'Mois'}
    ]


    const changeTitreProjet = (e) => {
        e.preventDefault();

        setmodificationTitre(false)

        let qs = 'idProjet='+projet.idProjet+'&NameProjet='+titreProjet
 
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"projets",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        })
    }

    const changePeriode = (sens) => {

        console.log(sens)

        const seconde_in_year = 31536000 

        if(modeAffichage === 'annee'){
            const time = sens === 'prev' ? -seconde_in_year : seconde_in_year 
            const date = Moment.unix(parseFloat(Moment(periode).unix())+time).format()
            setPeriode(date)
        }

        if(modeAffichage === 'trimestre'){
            const time = sens === 'prev' ? -(seconde_in_year/4) : seconde_in_year/4
            const date = Moment.unix(parseFloat(Moment(periode).unix())+time).format()
            setPeriode(date)
        }

        if(modeAffichage === 'mois'){

            let time = sens === 'prev' ? -(seconde_in_year/12) : seconde_in_year/12
            let date = Moment.unix(parseFloat(Moment(periode).unix())+time).format()

            // Gère le cas, si on est le 30 ou le 31 du mois
            if(Moment(date).format('M') === Moment(periode).format('M')){
                let time = sens === 'prev' ? -((seconde_in_year/352)*31) : ((seconde_in_year/352)*31)
                date = Moment.unix(parseFloat(Moment(periode).unix())+time).format()
            }
          
            setPeriode(date)
        }
    }

    const exportPeriodePDF = () =>{

        setLoadingPdf(true)

        let formData = new FormData()
        formData.append('ticketFind',JSON.stringify(ticketsFind))
        formData.append('idProjet',projet.idProjet)
        formData.append('periode',periodeString)
        formData.append('export',true)

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL+"projets/pdf/",
            data: formData,
            responseType: 'blob',
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Relevé consommation - ${projet.NameProjet}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setLoadingPdf(false)
        });

    }

    // Masquer Afficher Option More
    const hideTitleAffichageMore = () => {
        let the_title = document.querySelector(".container-titre");
        if(the_title) {
            the_title.style.display = "none"  
        }
    }

    const showTitleAffichageMore = () => {
        let the_title = document.querySelector(".container-titre");
        if(the_title) {
            the_title.style.display = "flex"
        }
    }


    const getEnveloppeRestantePeriode = () => {
        //console.log(periode)
        let sumEnveloppe = 0
        projet.Enveloppe.forEach((env)=>{
            console.log(periode,env.CreatedAt,periode < env.CreatedAt)
            if(periode < env.CreatedAt){
                sumEnveloppe += env.Credit
            }
        })
        return sumEnveloppe - consommationeuroperiode
    }


    const getEnveloppeRestantOnMoment = () => {
        //consommationSinceStart

        let sumEnveloppe = 0
        projet.Enveloppe.forEach((env)=>{
            if(periode >= env.CreatedAt){
                sumEnveloppe += parseInt(env.Credit)
            }
        })


        return sumEnveloppe - consommationSinceStart
    }
   

    return (
        <>
        <div className="container-infos-projet">
            <div className="infos">
                <div class="infos-actions">
                    <div class="container-titre">
                        
                        {projetIsFav && !VueClient && 
                        <svg onClick={() => deleteFav(projet.idProjet)} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
                        }
                        {!projetIsFav && !VueClient &&
                            <svg onClick={() => addFav(projet.idProjet)} aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg>
                        }
                    
        
                        {!modificationTitre && !VueClient &&
                            <h1 className="title-projet" onClick={()=>{setmodificationTitre(true)}}>{titreProjet}</h1>
                        }

                        {VueClient &&
                            <h1 className="title-projet" >{titreProjet}</h1>
                        }
                        
                        
                        {modificationTitre && !VueClient &&
                            <form className="modificationTitre" onSubmit={(e)=>{changeTitreProjet(e)}}>
                                <input type="text" value={titreProjet} onChange={(e)=>{setTitreProjet(e.target.value)}} />
                            </form>
                        }
                    </div>
                    
                    <div class="container-add-more">
                        {!VueClient && 
                        <div class="button add" onClick={()=>{setModalAddTicket(true)}}>
                            +
                        </div>
                        }

                        {!modeAffichageMore && !VueClient &&
                        <div class="button more" onClick={() => {hideTitleAffichageMore(); setmodeAffichageMore(true); }}>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="3" y="10" width="3" height="3" fill="black"/>
                                <rect x="10" y="10" width="3" height="3" fill="black"/>
                                <rect x="17" y="10" width="3" height="3" fill="black"/>
                            </svg>
                        </div>
                        }
                    </div>


                {modeAffichageMore  && !VueClient && <>
                
                    <div class="button modif-projet" onClick={() => {setmodalModifProjet(true)}}>
                        <div>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" class="svg-inline--fa fa-cog fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path></svg>
                        Paramètres
                        </div>
                    </div>


                    <div class="button facture">
                        <div className="factureToPdf" onClick={() => {exportPeriodePDF()}}>
                            {loadingPdf ? 
                                <div class="spinner-download"></div>
                                : 
                                <div>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-pdf" className="svg-inline--fa fa-file-pdf fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"></path></svg>
                                    <p>Exporter</p>
                                </div>
                            }
                        </div>
                    </div>


                    <div class="button import-ticket">
                        <div className="importTicket" onClick={() => {setmodalImportTicket(true)}}>
                            <div>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-import" className="svg-inline--fa fa-file-import fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M16 288c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h112v-64zm489-183L407.1 7c-4.5-4.5-10.6-7-17-7H384v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H152c-13.3 0-24 10.7-24 24v264h128v-65.2c0-14.3 17.3-21.4 27.4-11.3L379 308c6.6 6.7 6.6 17.4 0 24l-95.7 96.4c-10.1 10.1-27.4 3-27.4-11.3V352H128v136c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H376c-13.2 0-24-10.8-24-24z"></path></svg>
                                <p>Importer</p>
                            </div>
                        </div>
                    </div>

                    <div class="button temps-ressources" onClick={()=>{setmodalTempsRessources(true)}}>
                        <div>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.94287 0C4.85508 0 3.79171 0.322569 2.88724 0.926917C1.98277 1.53126 1.27782 2.39025 0.861536 3.39524C0.445254 4.40023 0.336336 5.5061 0.548555 6.573C0.760773 7.63989 1.2846 8.6199 2.05379 9.38909C2.82297 10.1583 3.80298 10.6821 4.86988 10.8943C5.93677 11.1065 7.04264 10.9976 8.04763 10.5813C9.05262 10.1651 9.91161 9.4601 10.516 8.55563C11.1203 7.65116 11.4429 6.5878 11.4429 5.5C11.4429 4.04131 10.8634 2.64236 9.83196 1.61091C8.80051 0.579462 7.40156 0 5.94287 0ZM5.94287 2.35714C6.05942 2.35714 6.17335 2.3917 6.27026 2.45645C6.36717 2.52121 6.4427 2.61324 6.4873 2.72092C6.5319 2.8286 6.54357 2.94708 6.52084 3.06139C6.4981 3.1757 6.44197 3.2807 6.35956 3.36312C6.27715 3.44553 6.17215 3.50165 6.05784 3.52439C5.94353 3.54713 5.82504 3.53546 5.71736 3.49086C5.60969 3.44626 5.51765 3.37072 5.4529 3.27382C5.38815 3.17691 5.35359 3.06298 5.35359 2.94643C5.35359 2.79014 5.41567 2.64025 5.52618 2.52974C5.6367 2.41923 5.78658 2.35714 5.94287 2.35714ZM7.5143 8.69196H4.37144V7.80803H5.50091V5.54911H4.7643V4.66518H6.38484V7.80803H7.5143V8.69196Z" fill="black"/>
                            </svg>
                            <p>Détails temps</p>
                        </div>
                    </div>


                    <div class="button delete" onClick={(e)=>{
                            if(window.confirm(`Êtes-vous sur de vouloir supprimer le projet ${projet.NameProjet} ?`)){
                                deleteProjet()
                            }}}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" className="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
                    
                    </div>


                    <div class="button close" onClick={() => {setmodeAffichageMore(false); showTitleAffichageMore()}}>
                         <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
                    </div>

                 </>

                }

            </div>

            <div className="periode">
                {true == false && <></>
                /*<div className="mode-affichage">
                    <button className={modeAffichage === 'tout' ? 'active' : '' } onClick={()=>{setModeAffichage('tout')}}>Tout</button>
                    <button className={modeAffichage === 'annee' ? 'active' : '' } onClick={()=>{setModeAffichage('annee')}}>Année</button>
                    <button className={modeAffichage === 'trimestre' ? 'active' : '' } onClick={()=>{setModeAffichage('trimestre')}}>Trimestre</button>
                    <button className={modeAffichage === 'mois' ? 'active' : '' } onClick={()=>{setModeAffichage('mois')}}>Mois</button>
                </div> */
                }

               
                <div className="select-periode">

                    {modeAffichage !== 'tout' &&
                        <>
                        
                            <div>
                                <svg aria-hidden="true" onClick={()=>{changePeriode('prev')}} focusable="false" data-prefix="far" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>
                                <svg aria-hidden="true" onClick={()=>{changePeriode('next')}} focusable="false" data-prefix="far" data-icon="chevron-right" class="svg-inline--fa fa-chevron-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>
                            </div>
                       
                        <p>{periodeString}</p>
                        </>
                    }   
                    <Select className="selection-periode" 
                    blurInputOnSelect={false}
                    options={optionsAffichage} 
                    defaultValue={optionsAffichage.find(element => element.value === modeAffichage)} 
                    onChange={(event)=>{setModeAffichage(event.value)}} 
                    isSearchable={false} />
                </div>

            </div>

            </div>
            <div className="synthese-visuelle" >
                <div className={ projet.modeProjet === 'enveloppe'  ? 'chiffres has-enveloppe' : 'chiffres'}>
                    <div className="chiffre">
                        {consommationperiode > 1 ?
                            <span> <NumberFormat value={Math.round(consommationperiode * 100) / 100} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix=" Tickets" /></span>
                            :
                            <span> <NumberFormat value={consommationperiode} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix=" Ticket" /></span>
                        }
                        <p>Consommation sur la période </p>
                    </div>

                    <div className="chiffre">
                        <span><NumberFormat value={Math.round(consommationeuroperiode * 100) / 100} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></span>
                        <p>Coût sur la période </p>
                    </div>

                    {projet.modeProjet === 'enveloppe' && 

                  
                    <div className={getEnveloppeRestantOnMoment() > 300  ? 'chiffre good' : 'chiffre warning'}>
                        <span> <NumberFormat value={getEnveloppeRestantOnMoment()} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></span>
                        Enveloppe restante
                    </div>
                    }

                </div>

                <Chart projet={projet} VueClient={VueClient} modeAffichage={modeAffichage} ticketsFind={ticketsFind}/>
            </div>
        </div>


        {!VueClient && <>
        <ModalExportPDF showModal={modalExportPDF} setShowModal={setmodalExportPDF} projet={projet}/>
        <ModalImportTicket showModal={modalImportTicket} setShowModal={setmodalImportTicket} projet={projet} />
        <ModalModifProjet showModal={modalModifProjet} setShowModal={setmodalModifProjet} projet={projet} />
        <ModalInfosClient showModal={modalInfosClient} setShowModal={setmodalInfosClient} projet={projet} />
        <ModalTempsRessources showModal={modalTempsRessources} 
        setShowModal={setmodalTempsRessources} 
        projet={projet}
        modeAffichage={modeAffichage}
        setModeAffichage={setModeAffichage}
        changePeriode={changePeriode}
        optionsAffichage={optionsAffichage} 
        periodeString={periodeString}
        ticketsFind = {ticketsFind}/>
        <ModalAddTicket showModal={modalAddTicket}  setShowModal={setModalAddTicket} />
        </>}

        </>
        
    )


}
export default InfoProjet;